import React from "react";

const ComingSoonPage = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center p-4 relative overflow-hidden">
      <h1 className="text-[50px] font-bold text-[#324647]">
        Coming Soon
      </h1>
      <p className="text-gray-500">
        The Product is under development and will be available soon. Stay tuned!
      </p>
    </div>
  );
};

// Add required keyframes
const style = document.createElement("style");
style.textContent = `
  @keyframes blob {
    0% { transform: translate(0px, 0px) scale(1); }
    33% { transform: translate(30px, -50px) scale(1.1); }
    66% { transform: translate(-20px, 20px) scale(0.9); }
    100% { transform: translate(0px, 0px) scale(1); }
  }
`;
document.head.appendChild(style);

export default ComingSoonPage;
