import React, { useEffect, useState } from "react";
import { Button } from "../../../../ui/button";
import {
  Activity,
  BarChart3,
  Calendar,
  CloudSun,
  DollarSign,
  Download,
  Moon,
  Sun,
} from "lucide-react";
import { Card, CardContent } from "../../../../ui/card";

const Dashboard = ({ setActiveModule }) => {
  const [greeting, setGreeting] = useState("");
  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) setGreeting("Good Morning");
    else if (hour < 18) setGreeting("Good Afternoon");
    else setGreeting("Good Evening");
  }, []);
  const today = new Date();
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const cards = [
    {
      id: 1,
      name: "Card 1",
      description: {
        benifits1: "5% cashback* on bill payments & recharges on Google Pay",
        benifits2: "4% cashback* on Swiggy, Zomato & Ola",
        benifits3: "1.5% cashback* on other spends",
        benifits4:
          "4 Complimentary lounge access at select domestic airports 1% fuel surcharge waiver",
      },
      image: "/images/card_1.png",
    },
    {
      id: 2,
      name: "Card 2",
      description: {
        benifits1: "8% cashback* on travel bookings via partner platforms",
        benifits2: "5% cashback* on dining at premium restaurants",
        benifits3: "2% cashback* on all online spends",
        benifits4: "Complimentary travel insurance and airport lounge access",
      },
      image: "/images/card_2.png",
    },
    {
      id: 3,
      name: "Card 3",
      description: {
        benifits1: "10% cashback* on international shopping and travel",
        benifits2: "6% cashback* on entertainment and streaming services",
        benifits3: "3% cashback* on retail purchases",
        benifits4: "Exclusive access to premium events and concierge services",
      },
      image: "/images/card_3.png",
    },
  ];

  const dayName = days[today.getDay()];
  const monthName = months[today.getMonth()];
  const date = today.getDate();
  const year = today.getFullYear();

  return (
    <div>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-semibold">Home</h2>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Card className="bg-[#324647] overflow-hidden">
            <CardContent className="px-4 py-8">
              <div className="flex items-center justify-between relative">
                <div className="flex flex-col gap-2">
                  <h1 className="text-3xl text-[#fcbe4f] font-bold">
                    {greeting}
                  </h1>
                  <p className="text-white">
                    Welcome to your operations dashboard
                  </p>
                </div>
                <div className="absolute -right-12 -bottom-12 opacity-40">
                  {greeting === "Good Morning" ? (
                    <CloudSun size={70} color="#fcbe4f" />
                  ) : greeting === "Good Afternoon" ? (
                    <Sun size={100} color="#fcbe4f" />
                  ) : (
                    <Moon color="#fcbe4f" size={70} />
                  )}{" "}
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="bg-white border border-[#324647] relative overflow-hidden"> 
            <CardContent className="p-4 flex items-center justify-between h-full">
              <div className="flex flex-col gap-2">
                <h1 className="text-3xl text-[#324647] font-bold mb-2">
                  {dayName}
                </h1>
                <p className="text-gray-500">
                  {monthName} {date}, {year}
                </p>
              </div>
              <Calendar size={100} className="text-[#324647] absolute -right-4 -bottom-4 opacity-20" />
            </CardContent>
          </Card>
        </div>
        <div>
          <h2 className="text-[#272727] font-semibold">
            Best-selling credit cards
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {cards.map((card) => (
              <Card key={card.id}>
                <CardContent className="p-4 flex items-center justify-between  h-full">
                  <div className="flex items-center gap-16">
                    <img
                      src={card.image}
                      alt={card.name}
                      className="w-[300px] object-contain"
                    />
                    <div>
                      <ul className="list-disc">
                        {Object.values(card.description).map((desc, index) => (
                          <li
                            key={index}
                            className="my-2 text-[16px] text-gray-700"
                          >
                            {desc}
                          </li>
                        ))}
                      </ul>
                      <Button
                        className="border border-[#324647] text-[#324647] mt-4"
                        onClick={() => setActiveModule("creditcardanalyzer")}
                      >
                        Explore More
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
