import React from "react";

const CreditCardAnalyzer = () => {
  return (
    <div>
      <iframe
        src="https://credit-card-recommender.azurewebsites.net"
        className="w-full h-[80vh] border-none"
        title="HTML File"
      ></iframe>
    </div>
  );
};

export default CreditCardAnalyzer;
