import React from 'react'

const OpsEnquiryAssistance = () => {
  return (
    <div>
       <iframe
        src="https://ops-enquiry-as-aua8gsdwdqgcdve9.southeastasia-01.azurewebsites.net/"
        className="w-full h-[80vh] border-none"
        title="HTML File"
      ></iframe>
    </div>
  )
}

export default OpsEnquiryAssistance
