import React, { useEffect, useState } from "react";
import { Button } from "../../../ui/button";
import { Card, CardContent } from "../../../ui/card";
import { Input } from "../../../ui/input";
import {
  CreditCard,
  Bell,
  Search,
  User,
  FileText,
  AudioLines,
  HelpCircle,
  Settings,
  MoveLeft,
  Sun,
  Moon,
  CloudSun,
  House,
} from "lucide-react";
import Dashboard from "./pages/Dashboard";
import CreditCardAnalyzer from "./pages/CreditCard";
import ComingSoonPage from "./pages/ComingSoonPage";
import { Link } from "react-router-dom";
import OpsEnquiryAssistance from "./pages/OpsEnquiryAssistance";

const BFSIPortal = () => {
  const [activeModule, setActiveModule] = useState("dashboard");
  const [userDetails, setUserDetails] = useState({});

  const [greeting, setGreeting] = useState("");
  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) setGreeting("Good Morning");
    else if (hour < 18) setGreeting("Good Afternoon");
    else setGreeting("Good Evening");
  }, []);

  useEffect(() => {
    const sessionKey = sessionStorage.getItem("msal.account.keys");
    const data = JSON.parse(sessionKey);
    const sessionData = data[0];
    const userData = sessionStorage.getItem(sessionData);

    if (userData) {
      const parsedData = JSON.parse(userData).idTokenClaims;
      setUserDetails(parsedData);
    }
  }, []);
  const userName = localStorage.getItem("userName");
  const modules = [
    {
      id: "dashboard",
      icon: <House className="w-5 h-5" />,
      title: "Home",
      tag: "Overview",
    },
    {
      id: "opsenquiryassistance",
      icon: <HelpCircle className="w-5 h-5" />,
      title: "Ops Enquiry Assistance",
      tag: "Growth",
    },
    {
      id: "creditcardanalyzer",
      icon: <CreditCard className="w-5 h-5" />,
      title: "Credit Card Recommender",
      tag: "Active",
    },
    {
      id: "resolutionmanager",
      icon: <Settings className="w-5 h-5" />,
      title: "Resolution Manager",
      tag: "Protected",
    },
    {
      id: "documentmanager",
      icon: <FileText className="w-5 h-5" />,
      title: "Document Manager",
      tag: "Protected",
    },
    {
      id: "voicereminder",
      icon: <AudioLines className="w-5 h-5" />,
      title: "Voice Reminder",
      tag: "Protected",
    },
  ];

  const ModuleContent = ({ moduleId }) => {
    const contentMap = {
      dashboard: (
        <div className="p-6">
          <Dashboard setActiveModule={setActiveModule} />
        </div>
      ),
      opsenquiryassistance: (
        <div className="h-full">
          <OpsEnquiryAssistance />
        </div>
      ),
      creditcardanalyzer: (
        <div className="h-full">
          <CreditCardAnalyzer />
        </div>
      ),
      resolutionmanager: (
        <div className="p-6">
          <h2 className="text-2xl font-semibold mb-6">Resolution Manager</h2>
          <ComingSoonPage />
        </div>
      ),
      documentmanager: (
        <div className="p-6">
          <h2 className="text-2xl font-semibold mb-6">Document Manager</h2>
          <ComingSoonPage />
        </div>
      ),
      voicereminder: (
        <div className="p-6">
          <h2 className="text-2xl font-semibold mb-6">Voice Reminder</h2>
          <ComingSoonPage />
        </div>
      ),
    };

    return contentMap[moduleId] || null;
  };

  return (
    <div className="w-full h-screen max-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <div className="h-16 bg-white border-b px-6 flex items-center justify-between">
        <div className="flex items-center space-x-8">
          <img src="/images/inextlabs-logo.png" alt="logo" width={130} />
        </div>

        <div className="flex items-center space-x-4">
          <Button variant="ghost" className="relative">
            <Bell className="w-5 h-5" />
            <span className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full text-white text-xs flex items-center justify-center">
              3
            </span>
          </Button>
          <div className="w-8 h-8 rounded-full bg-[#324647] flex items-center justify-center text-white">
            <User className="w-4 h-4" color="#fcbe4f" />
          </div>
        </div>
      </div>
      <div className="flex px-4 py-6 items-center justify-between">
        <div className="flex gap-4 items-center">
          <Link to="/">
            <MoveLeft
              size={40}
              className="p-2 border rounded-full text-[#9ca3af]"
            />
          </Link>
          <h4 className="text-[#324647] text-3xl font-light ">
            Welcome,{" "}
            <span className="text-[#324647] text-3xl font-normal">
              {userDetails.name}
            </span>
          </h4>
        </div>
        <div className="flex gap-4 items-center">
          <div className="relative">
            <Search className="w-4 h-4 absolute left-3 top-2.5 text-gray-600 mt-[2px]" />
            <Input
              className="pl-10 w-64 rounded-[20px] bg-white placeholder-white::placeholder focus:ring-0"
              placeholder="Search anything..."
            />
          </div>
        </div>
      </div>
      <div className="flex-1 flex overflow-hidden m-4 mt-0 gap-6">
        {/* Left Side */}
        <div className="w-1/5 p-6 overflow-y-auto rounded-[30px] bg-[#324647]">
          {/* Module Cards */}

          <div className="space-y-3 mt-6">
            {modules.map((module) => (
              <div
                key={module.id}
                onClick={() => setActiveModule(module.id)}
                className={`group cursor-pointer transform transition-all duration-300 
                  ${
                    activeModule === module.id
                      ? "scale-[1.02]"
                      : "hover:scale-[1.01]"
                  }`}
              >
                <Card
                  className={`overflow-hidden transition-colors duration-200 
                  ${activeModule === module.id ? "bg-[#fcbe4f]" : ""}`}
                >
                  <CardContent className="p-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-4">
                        <div
                          className={`flex items-center justify-center transform transition-transform duration-300 ${
                            activeModule === module.id
                              ? "text-[#324647]"
                              : "text-[#fcbe4f]"
                          }`}
                        >
                          {module.icon}
                        </div>
                        <div>
                          <div className="flex items-center space-x-2">
                            <h5
                              className={`font-semibold text-[15px] transition-colors duration-200  ${
                                activeModule !== module.id
                                  ? "text-[#fff]"
                                  : "text-[#324647]"
                              }`}
                            >
                              {module.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>

        {/* Right Side - Module Content */}
        <div className="w-4/5 bg-white border overflow-y-auto h-full rounded-[30px]">
          <ModuleContent moduleId={activeModule} />
        </div>
      </div>
    </div>
  );
};

export default BFSIPortal;
